.ui-container {
    width: 100%;
    height: 100%;
}
@mixin border-box {
    box-sizing: border-box;
}
%flex {
    display: flex;
    flex-wrap: nowrap;
}
%flex-wrap {
    display: flex;
    flex-wrap: wrap;
} 
*,
*:after,
*:before {
    margin: 0;
    padding: 0;
    @include border-box;
}
%clearfix {
    *zoom: 1;

    &:before,
    &:after {
        content: " ";
        display: table;
    }
    &:after {
        clear: both;
    }
}
%row {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	margin: 0;
	padding: px(getBaseline()) 0;
    
    @include break($tab) {
        padding: px(getBaseline(4)) 0;
		flex-wrap: nowrap;
	}
	@include break($hd) {
        padding: px(getBaseline(6)) 0;
		flex-wrap: nowrap;
	}

	&.wrap {
		flex-wrap: wrap;
		-webkit-flex: 1 1 auto;
		flex: 1 1 auto;
	}
	&.pad-top {
		margin-top: px(getBaseline(16));
	}
	&.float-right {
        @include break($tab) {
            justify-content: flex-end;
        }
	}
}
.grid-container {
    @include border-box;
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: px($max-mobile);
    padding: 0 px($offset-mobile);

    @include break($tab) {
        max-width: px($max-tab);
	    padding: 0 px($offset-tab);
    }
    @include break($desk) {
        max-width: px($max-desk);
	    padding: 0 px($offset-desk);
    }
    @include break($hd) {
        max-width: px($max-hd);
	    padding: 0 px($offset-hd);
    }
}
%column {
    position: relative;
    width: 100%;
    padding: 0 px($gutters-mobile/2);
    
    @include break($tab) {
        width: auto;
		padding: 0 px($gutters-tab/2);
	}
    @include break($desk) {
        width: auto;
		padding: 0 px($gutters-desk/2);
	}
	@include break($hd) {
        width: auto;
		padding: 0 px($gutters-hd/2);
	}
}
.container {
    width: 100%;
    height: 100%;
}

// MOBILE
@for $i from 1 through $cols-mobile {
    %mobile-#{$i} {
        max-width: ((100% / $cols-mobile) * $i);
        flex: 1 1 ((100% / $cols-mobile) * $i);
    }
    %push-mobile-#{$i} {
        margin-left: ((100% / $cols-mobile) * $i) !important;
        padding-left: px($gutters-mobile) !important;
    }
}

// TABLET
@include break($tab) {
    @for $i from 1 through $cols-tab {
        %tab-#{$i} {
            max-width: ((100% / $cols-tab) * $i);
            flex: 1 1 ((100% / $cols-tab) * $i);
        }

        %push-tab-#{$i} {
            margin-left: ((100% / $cols-tab) * $i) !important;
            padding-left: px($gutters-tab) !important;
        }
    }
}

// DESKTOP
@include break($desk) {
    @for $i from 1 through $cols-desk {
        %desk-#{$i} {
            max-width: ((100% / $cols-tab) * $i);
            flex: 1 1 ((100% / $cols-tab) * $i);
        }

        %push-desk-#{$i} {
            margin-left: ((100% / $cols-desk) * $i) !important;
            padding-left: px($gutters-desk) !important;
        }
        %pull-desk-#{$i} {
            margin-left: (-(100% / $cols-desk) * $i) !important;
            padding-left: px($gutters-desk) !important;
        }
    }
}

// DESKTOP HD
@include break($hd) {
    @for $i from 1 through $cols-hd {
        %hd-#{$i} {
            max-width: ((100% / $cols-hd) * $i);
            flex: 1 1 ((100% / $cols-hd) * $i);
        }

        %push-hd-#{$i} {
            margin-left: ((100% / $cols-hd) * $i) !important;
            padding-left: px($gutters-hd) !important;
        }
        %pull-hd-#{$i} {
            margin-left: (-(100% / $cols-hd) * $i) !important;
            padding-left: px($gutters-hd) !important;
        }
    }
}
.no-wrap {
    flex-wrap: nowrap;
}
.no-margin {
    margin: 0;
    padding: 0;
}
.no-margin-top {
    margin-top: 0;
    padding-top: 0;
}
.no-margin-bottom {
    margin-bottom: 0;
    padding-bottom: 0;
}
.no-margin-left {
    margin-left: 0;
    padding-left: 0;
}
.no-margin-right {
    margin-right: 0;
    padding-right: 0;

}
