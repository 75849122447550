.generic-button {
	height: px(42);
	padding: 0 px(20);
	background-color: $green;
	border: none;
	border-radius: px(3);
	font-size: rem(18);
	color: $white;
	text-transform: uppercase;
	@include rollover;
	&:hover {
		cursor: pointer;
		background-color: $lgreen;
	}
}
.no-content {
	p {
		font-size: rem(24);
	}
}
#selling-points {
	background-color: $green;
	@include break($desk) {
		height: px(140);
	}
	@include break($hd) {
		height: px(132);
	}
	.grid-container {
		@extend %flex-wrap;
		height: 100%;
		@include break($desk) {
			flex-wrap: nowrap;
		}
	}
	.point {
		@extend %flex;
		flex: 1 1 100%;
		position: relative;
		align-items: center;
		justify-content: center;
		height: auto;
		@extend %column;
		padding-top: px(getBaseline());
		padding-bottom:px(getBaseline());
		border-bottom: px(1) solid $white;
		color: $white;
		@include animate(all, .25s, ease-in-out);
		&:nth-child(2) {
			@include break($tab) {
				border-right: px(1) solid $white;
			}
			@include break($desk) {
				border-right: none;
			}
		}
		&:last-child {
			border-bottom: none;
			@include break($tab) {
				padding-top: px(getBaseline());
				padding-bottom:px(getBaseline());
				border-right: px(1) solid $white;
			}
		}
		@include break($tab) {
			flex: 1 1 50%;
			height: px(130);
			border-bottom: none;
			border-left: px(1) solid $white;
		}
		@include break($desk) {
			height: 100%;
		}
		.icon {
			display: block;
			min-width: px(64);
			width: px(64);
			height: px(64);
			margin-right: px(6);
			@include break($desk) {
				min-width: px(42);
				width: px(42);
				height: px(42);
			}
			@include break($hd) {
				min-width: px(64);
				width: px(64);
				height: px(64);
				
			}
			img, .svg {
				display: block;
				width: 100%;
				height: 100%;
				path, circle {
					fill: $white;
				}
			}
		}
		.content {
			flex: 1 1 100%;
			@include animate(all, .25s, ease-in-out);
		}
		.title {
			position: relative;
			@include heading;
			text-transform: uppercase;
			@include animate(all, .25s, ease-in-out);
			font-size: rem(18);
			@include break($desk) {
				font-size: rem(20);
			}
			@include break($hd) {
				font-size: rem(22);
			}
		}
		$copy_anime: opacity, height;
		.copy {
			position: relative;
			top: 0;
			max-height: 100%;
			font-size: rem(15);
			visibility: visible;
			opacity: 1;
			// @include animate(all, .25s, ease-in-out);
			@include break($desk) {
				max-height: 0;
				visibility: hidden;
				opacity: 0;
			}
		}
		&:hover {
			cursor: help;
			background-color: $lgreen;
			.content {
				position: relative;
			}
			.copy {
				visibility: visible;
				opacity: 1;
				max-height: 100%;
			}
		}
	}
}
#faqs {
	display: block;
	background-color: $white;
	padding: px(getBaseline(6)) 0;
	@include break($tab) {
		padding: px(getBaseline(8)) 0;
	}
	@include break($desk) {
		padding: px(getBaseline(10)) 0;
	}
	@include break($hd) {
		padding: px(getBaseline(14)) 0;
	}
	.grid-container {
		@extend %flex-wrap;
		justify-content: center;
	}
	.content {
		@extend %mobile-4;
		@include break($desk) {
			@extend %desk-10;
		}
		@include break($hd) {
			@extend %hd-12;
		}
	}
	.faq-group {
		margin-bottom: px(getBaseline());
	}
	.faq {
		display: flex;
		// @extend %column;
		.toggle-icon {
			display: table;
			position: relative;
			width: px(32);
			height: px(32);
			@extend %column;
			@include animate(transform, .25s, ease-in-out);
			.svg {
				width: px(32);
				height:px(32);
				#plus, #minus {
					fill: $gray;
				}
			}
		}
		.faq-container {
			flex: 1;
			max-height: px(30);
			@extend %column;
			overflow: hidden;
			@include animate(max-height, .25s, ease-in-out);
			.question {
				@include animate(color, .25s, ease-in-out);
			}
		}
		&:hover {
			.toggle-icon {
				cursor: pointer;
			}
			.question {
				cursor: pointer;
				color: $gray;
			}
		}
		&.active {
			.toggle-icon {
				transform: rotate(-135deg);
			}
			.faq-container {
				max-height: px(3000);
			}
			.question {
				color: $green;
			}
		}
	}
}
.inputfield {
	@extend %flex;
	margin-bottom: px(getBaseline());
	label {
		display: block;
		width: 20%;
		padding-top: px(getBaseline(1));
		@include break($hd) {
			font-size: rem(18);
		}
	}
	input[type=text], textarea {
		border: px(1) solid rgba($gray, .25);
		@include regular;
		font-size: rem(16);
		color: $dgray;
		@include break($hd) {
			font-size: rem(18);
		}
		&.error {
			border: px(1) solid #D0021B;
		}
	}
	input[type=text] {
		flex-grow: 1;
		height: px(42);
		padding: 0 px(15);
	}
	textarea {
		flex-grow: 1;
		height: px(200);
		padding: px(15);
	}
}
#errors {
	margin-bottom: px(getBaseline());
	color: #D0021B;
	p {
		margin-bottom: px(getBaseline(1));
		font-size: rem(14);
	}
}