header {
	position: fixed;
	z-index: 999;
	top: 0;
	width: 100%;
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+100 */
	background: -moz-linear-gradient(top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0 ); /* IE6-9 */
	@include break($hd) {
		height: px(192);
	}
	.grid-container {
		@extend %flex;
	}
	#brandmark {
		margin-top: px(18);
		@extend %column;
		.svg {
			#logotype {
				display: none;
				@include break($tab) {
					display: block;
				}
			}
		}
	}
	#mobile-menu {
		position: absolute;
		z-index: 9999;
		top: px(getBaseline(5));
		right: px($offset-mobile + $gutters-mobile);
		@include break($tab) {
			right: px($offset-tab + $gutters-tab);
		}
		@include break($desk) {
			display: none;
		}
		.svg {
			#icon-fill {
				fill: $green;
			}
		}
		&:hover {
			cursor: pointer;
		}
		&.active {
			.svg {
				#icon-fill {
					fill: $white;
					#line1, #line3 {
						opacity: .25;
					}
				}
			}
		}
	}
	ul#primary {
		@extend %flex-wrap;
		position: fixed;
		z-index: 9998;
		left: 100%;
		width: 100%;
		height: 100%;
		align-items: center;
		align-content: center;
		@extend %column;
		background-color: $green;
		@include animate(left, .15s, ease-in-out);
		@include break($desk) {
			flex: 1 1 auto;
			justify-content: flex-end;
			position: relative;
			left: 0;
			height: auto;
			background-color: rgba($white, 0);
		}
		&.active {
			left: 0;
		}
		li.menu-item {
			width: 100%;
			height: px(77);
			font-size: rem(32);
			text-transform: uppercase;
			@include rollover;
			@include break($desk) {
				width: auto;
				border-bottom: px(2) solid rgba($green, 0);
				font-size: rem(16);
			}
			&:hover {
				background-color: rgba($white, .1);
				@include break($desk) {
					background-color: rgba($white, 0);
					border-bottom: px(2) solid rgba($green, 1);
				}
			}
			a {
				@extend %flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				color: $white;
				text-align: center;
				@include animate(padding-bottom, .25s, ease-in-out);
				@include break($desk) {
					align-items: flex-end;
					height: px(72);
					padding: 0 px(14) px(14);
					color: $green;
				}
				&:hover {
					// background-color: $lgreen;
					// color: $lgreen;
					padding-bottom: px(18);
				}
			}
		}
		li.current-menu-item {
			background-color: rgba($white, .1);
			@include break($desk) {
				background-color: rgba($white, 0);
				border-bottom: px(2) solid $green;
			}
			a {
				color: $white;
				@include break($desk) {
					background-color: $green;
				}
			}
		}
	}
}