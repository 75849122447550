@import 'elements';
@import 'header';
.col {
	@extend %column;
}
.hero {
	@extend %flex;
	align-items: flex-end;
	position: relative;
	width: 100%;
	height: px(520);
	background-color: rgba($black, .2);
	@include break($tab) {
		height: px(680);
	}
	@include break($desk) {
		height: px(600);
	}
	@include break($hd) {
		height: px(768);
	}
	.image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}
	.hero-gradient {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 80%;
		/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&0+0,1+100 */
		background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
		background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
		background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
		@include break($desk) {
			height: 40%;
		}
	}
	.content {
		left: 0;
		@extend %column;
		@include break($tab) {
			@extend %tab-7;
			margin-bottom: px(getBaseline(10));
		}
		@include break($desk) {
			@extend %desk-6;
			margin-bottom: px(getBaseline(10));
		}
	}
	h1 {
		margin-bottom: px(getBaseline());
		@include regular;
		line-height: 1.3;
		color: $green;
	}
	p {
		font-size: rem(18);
		color: $dgray;
		@include break($tab) {
			font-size: rem(20);
		}
		@include break($desk) {
			font-size: rem(24);
		}
	}
}
#welcome {
	@extend %flex;
	position: relative;
	height: auto;
	margin-top: px(getBaseline());
	padding-bottom: px(2);
	border-bottom: px(2) solid $dgray;
	@include break($tab) {
		height: px(320);
		margin-top: px(3);
		padding-bottom: px(2);
	}
	.image {
		flex: 1 1 100%;
		height: px(200);
		@include break($tab) {
			height: 100%;
		}
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: left;
		}
	}
	.grid-container {
		@extend %flex-wrap;
		flex: 1 1 100%;
		@include break($tab) {
			min-height: 100%;
			flex-wrap: nowrap;
		}
	}
	.spacer {
		display: none;
		flex: 1 1 8%;
		@extend %column;
		@include break($tab) {
			display: block;
		}
		@include break($hd) {
			@extend %hd-8;
		}
	}
	.content {
		@extend %flex;
		flex-direction: column;
		justify-content: center;
		flex: 1 1 100%;
		@extend %column;
		// padding-top: px(getBaseline());
		// border-top: px(2) solid $dgray;
		@include break($tab) {
			height: 100%;
			padding-top: 0;
		}
	}
	h1 {
		line-height: .8;
		font-size: rem(32);
		@include break($hd) {
			font-size: rem(52);
		}
	}
	h2 {
		@include heading-light;
		strong {
			@include heading;
		}
	}
	p {
		margin-top: px(getBaseline(2));
		color: $gray;
		&:last-child {
			margin-bottom: 0;
		}
	}
}
#vision-values {
	@extend %row;
	.grid-container {
		@extend %flex-wrap;
		justify-content: space-between;
		@include break($tab) {
			flex-wrap: nowrap;
		}
	}
	#vision, #values {
		@extend %column;
		@include break($tab) {
			@extend %tab-6;
		}
		@include break($desk) {
			@extend %desk-7;
		}
		h2 {
			margin-bottom: px(getBaseline());
		}
		ul {
			@extend %list;
		}
		p, li {
			color: $gray;
		}
	}
}
#news-home {
	@extend %row;
	background-color: $lgray;
	.grid-container {
		@extend %flex;
		flex-direction: column;
		align-items: center;
	}
	#news-previews {
		@extend %flex;
		justify-content: center;
		width: 100%;
		margin: px(getBaseline(6)) 0;
	}
	.news-preview {
		@extend %flex;
		flex-direction: column;
		@extend %column;
		flex: 0 1 calc(100% / 4);
		.image {
			width: 100%;
			@include break($hd) {
				height: px(198);
			}
			img {
				width: 100%;
				height: 100%;
				object-fit: cover;
				object-position: center;
			}
		}
		.content {
			@extend %flex;
			flex-direction: column;
			height: 100%;
			padding: px(20);
			background-color: $green;
			color: $white;
		}
		h1 {
			margin-bottom: px(getBaseline(2));
			@include break($desk) {
				font-size: rem(18);
			}
		}
		p {
			flex-grow: 1;
			font-size: rem(16);
		}
		a {
			align-self: flex-end;
			font-size: rem(14);
			color: $yellow;
			@include rollover;
			&:hover {
				color: $lyellow;
				text-decoration: underline;
			}
		}
	}
}
#contact-us {
	padding: px(getBaseline(14)) 0;
	.grid-container {
		@extend %flex-wrap;
		justify-content: center;
	}
	h2 {
		margin-bottom: px(getBaseline(5));
	}
	#contact-info, #contact-form {
		@extend %mobile-4;
		@extend %column;
	}
	#contact-info {
		@include break($desk) {
			@extend %desk-6;
		}
		@include break($hd) {
			@extend %hd-6;
		}
	}
	#contact-form {
		@include break($desk) {
			@extend %desk-6;
		}
		@include break($hd) {
			@extend %hd-6;
		}
		.generic-button {
			margin-left: 20%;
		}
	}
}
@import 'footer';