footer {
	color: $white;
	p, li {
		@include break($hd) {
			font-size: rem(16);
		}
	}
	a {
		color: $yellow;
		&:hover {
			color: $lyellow;
			text-decoration: underline;
			@include rollover;
		}
	}
	#general {
		@extend %row;
		background-color: $gray;
		// @include break($hd) {
		// 	padding: px(getBaseline(6)) 0 px(getBaseline(10));
		// }
		.grid-container {
			@extend %flex-wrap;
		}
		.footer-column {
			@extend %column;
		}
		.footer-group {
			margin-bottom: px(getBaseline(4));
		}
		h3 {
			margin-bottom: px(getBaseline(2));
		}
		li {
			margin-bottom: px(getBaseline(2));
		}
		#contact-info {
			@extend %mobile-4;
			@include break($tab) {
				@extend %tab-4;
			}
			@include break($desk) {
				@extend %desk-3;
			}
			@include break($hd) {
				@extend %hd-5;
			}
		}
		#hours {
			@extend %mobile-4;
			@include break($tab) {
				@extend %tab-4;
			}
			@include break($desk) {
				@extend %desk-3;
			}
			@include break($hd) {
				@extend %hd-4;
			}
		}
		#footer-nav {
			@extend %mobile-4;
			@include break($tab) {
				@extend %tab-4;
			}
			@include break($desk) {
				@extend %desk-4;
			}
			@include break($hd) {
				@extend %hd-3;
			}
		}
		#email-signup {
			@extend %mobile-4;
			@include break($hd) {
				@extend %hd-4;
			}
		}
	}
	#legal-social {
		@extend %flex;
		align-items: center;
		padding: px(getBaseline()) 0;
		background-color: $dgray;
		font-size: rem(14);
		@include break($tab) {
			height: px(50);
			padding: 0;
		}
		.grid-container {
			@extend %flex-wrap;
			@include break($tab) {
				flex-wrap: nowrap;
			}
		}
		#legal, #social {
			@extend %flex;
			flex: 1 1 100%;
			align-items: center;
			@extend %column;
		}
		#social {
			@extend %flex;
			justify-content: center;
			padding-top: px(getBaseline());
			@include break($tab) {
				justify-content: flex-end;
				padding-top: 0;
			}
			a {
				width: px(32);
				height: px(32);
				margin-left: px(16);
				&:first-child {
					margin-left: 0;
				}
				img, .svg {
					width: 100%;
					height: 100%;
				}
				.svg {
					path {
						fill: $yellow;
					}
				}
				&:hover {
					.svg {
						path {
							fill: $lyellow;
						}
					}
				}
			}
		}
	}
}