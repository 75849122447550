// hd
$hd: 1025;
$cols-hd: 16;
$gutters-hd: 30;
$offset-hd: 40;
$max-hd: 1366 + ($offset-hd * 2) + ($gutters-hd / 2);

// desktop
$desk: 861;
$cols-desk: 16;
$gutters-desk: 20;
$offset-desk: 32;
$max-desk: 960 + ($offset-desk * 2) + ($gutters-desk / 2);

// tablet
$tab: 661;
$cols-tab: 12;
$gutters-tab: 15;
$offset-tab: 20;
$max-tab: 728 + ($offset-tab * 2) + ($gutters-tab / 2);

// mobile
$mobile: 320;
$cols-mobile: 4;
$gutters-mobile: 5;
$offset-mobile: 10;
$max-mobile: 660 + ($offset-mobile * 2) + ($gutters-mobile / 2);

// color palette
$black: #000000;
$white: #ffffff;
$dgray: #525259;
$gray: #71727A;
$lgray: #F6F6FF;
$lgreen: #66B95D;
$green: #387D45;
$lyellow: #EBDE8B;
$yellow: #DECD59;

// fonts
@font-face {font-family: 'TwCenMTPro-Light';src: url('../fonts/394EB1_0_0.eot');src: url('../fonts/394EB1_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/394EB1_0_0.woff2') format('woff2'),url('../fonts/394EB1_0_0.woff') format('woff'),url('../fonts/394EB1_0_0.ttf') format('truetype');}
@font-face {font-family: 'TwCenMTPro-Bold';src: url('../fonts/394A1C_0_0.eot');src: url('../fonts/394A1C_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/394A1C_0_0.woff2') format('woff2'),url('../fonts/394A1C_0_0.woff') format('woff'),url('../fonts/394A1C_0_0.ttf') format('truetype');}
@font-face {font-family: 'AvenirLT-Medium';src: url('../fonts/2DBF25_0_0.eot');src: url('../fonts/2DBF25_0_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2DBF25_0_0.woff2') format('woff2'),url('../fonts/2DBF25_0_0.woff') format('woff'),url('../fonts/2DBF25_0_0.ttf') format('truetype');}  
@font-face {font-family: 'AvenirLT-Roman';src: url('../fonts/2DBF25_1_0.eot');src: url('../fonts/2DBF25_1_0.eot?#iefix') format('embedded-opentype'),url('../fonts/2DBF25_1_0.woff2') format('woff2'),url('../fonts/2DBF25_1_0.woff') format('woff'),url('../fonts/2DBF25_1_0.ttf') format('truetype');}

@mixin heading {
	font-family: 'TwCenMTPro-Bold', sans-serif;
}
@mixin heading-light {
	font-family: 'TwCenMTPro-Light', sans-serif;
}
@mixin regular {
	font-family: 'AvenirLT-Roman', sans-serif;
}
@mixin medium {
	font-family: 'AvenirLT-Medium', sans-serif;
}

// font weights

// typography
h1 {
	font-size: rem(28);
	@include break($tab) {
		font-size: rem(32);
	}
	@include break($desk) {
		font-size: rem(38);
	}
	@include break($hd) {
		font-size: rem(48);
	}
}
h2 {
	margin-bottom: px(getBaseline());
	font-size: rem(24);
	@include break($tab) {
		font-size: rem(28);
	}
	@include break($desk) {
		font-size: rem(32);
	}
	@include break($hd) {
		font-size: rem(36);
	}
	&.light {
		@include heading-light;
	}
}
h3 {
	font-size: rem(18);
	@include break($tab) {
		font-size: rem(20);
	}
	@include break($desk) {
		font-size: rem(24);
	}
}
h1, h2, h3 {
	@include heading;
	text-transform: uppercase;
}
p, ul, ol {
	margin-bottom: px(getBaseline(4));
	font-size: rem(16);
	@include break($hd) {
		font-size: rem(18);
	}
}
%list {
	list-style: disc;
	margin-left: px(24);
	li {
		margin-bottom: px(getBaseline());
		padding-left: px(12);
	}
}
a {
	text-decoration: none;
}
strong, b {
	@include medium;
}
// general styles
body {
	background-color: $white;
	@include regular;
	color: $dgray;
	line-height: 1.4;
}
.content {
	a {
		color: $green;
		&:hover {
			text-decoration: underline;
		}
	}
	ul {
		list-style: disc;
		margin-left: px(24);
		li {
			margin-bottom: px(getBaseline(2));
			padding-left: px(12);
		}
	}
}