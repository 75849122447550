@function px($num) {
	@return ($num)+px;
}
// Convert pixels to root em
@function rem($num) {
	@return ($num/16)+rem;
}

// Convert pixels to percentage
@function percent($num, $max-width) {
	@return percentage($num/$max-width);
}

// Define responsive breakpoint
@mixin break($size, $rule: min-width) {
	@media screen and ($rule: px($size)) {
		@content;
	}
}

// Calculate Baseline (Primarily used for calculating "line-height" or "margin-bottom")
@function getBaseline($num: 3, $multiplier: 6) {
	@return $multiplier*$num;
}

// Animation snippit
@mixin animate($focus: all, $duration: 0.5s, $function: ease, $delay: 0s) {
	-webkit-transition-property: $focus;
	-webkit-transition-duration: $duration;
	-webkit-transition-timing-function: $function;
	-webkit-transition-delay: $delay;
	-moz-transition-property: $focus;
	-moz-transition-duration: $duration;
	-moz-transition-timing-function: $function;
	-moz-transition-delay: $delay;
	-o-transition-property: $focus;
	-o-transition-duration: $duration;
	-o-transition-timing-function: $function;
	-o-transition-delay: $delay;
	transition-property: $focus;
	transition-duration: $duration;
	transition-timing-function: $function;
	transition-delay: $delay;
}

// Keyframes snippit
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}
	@-moz-keyframes #{$name} {
		@content;
	}
	@-ms-keyframes #{$name} {
		@content;
	}
	@keyframes #{$name} {
		@content;
	}
}
@mixin keyframe-animation($animate) {
	-webkit-animation: $animate;
	-moz-animation: $animate;
	-ms-animation: $animate;
	animation: $animate;
}
@mixin rollover() {
	$list: background-color, color, border;
	@include animate($list, 0.25s, ease-in-out);
}

// General CSS "reset"
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
	border: 0;
	font-family: inherit;
	font-size: 100%;
	font-style: inherit;
	font-weight: inherit;
	margin: 0;
	outline: 0;
	padding: 0;
	vertical-align: baseline;
}

// remember to define focus styles
:focus {
	outline: 0;
}

body {
	background-color: #eee;
	line-height: 1;
}

ol, ul {
	list-style: none;
}

//tables still need 'cellspacing="0"' in the markup
table {
	border-collapse: separate;
	border-spacing: 0;
}

caption, th, td {
	font-weight: normal;
	text-align: left;
}

blockquote:before, blockquote:after,
q:before, q:after {
	content: "";
}

blockquote, q {
	quotes: "" "";
}

a img {
	border: 0;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
